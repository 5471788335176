import React, { useEffect } from "react";
import anime from "animejs";

export default function S2({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s2",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div className="mx-auto mb-2" id="svg">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 46"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#009845",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s2"
          d="M1.46,32.31a1,1,0,0,1,1-1h17a1,1,0,0,1,1,1v8.94a1,1,0,0,1-1,1H2.45a1,1,0,0,1-1-1Zm13.83-4.63v2.18H6.56V27.68a4.37,4.37,0,1,1,8.73,0m-11.65,0a7.29,7.29,0,1,1,14.57,0v2.18H16.75V27.68a5.83,5.83,0,1,0-11.65,0v2.18H3.64Zm-1.19,16h17a2.45,2.45,0,0,0,2.45-2.45V32.31a2.44,2.44,0,0,0-2.19-2.43v-2.2a8.74,8.74,0,0,0-17.48,0v2.2A2.44,2.44,0,0,0,0,32.31v8.94A2.45,2.45,0,0,0,2.45,43.7"
        />
        <path
          class="s2"
          d="M10.92,35.69a.73.73,0,1,1-.72.73.73.73,0,0,1,.72-.73m-.72,2.79v1.58h1.45V38.48a2.19,2.19,0,1,0-1.45,0"
        />
        <path
          class="s2"
          d="M8.74,3.64a2.19,2.19,0,0,1,2.18-2.18H40.06a2.18,2.18,0,0,1,2.18,2.18V32.78A2.18,2.18,0,0,1,40.06,35H23.31v1.46H40.06a3.64,3.64,0,0,0,3.64-3.64V3.64A3.64,3.64,0,0,0,40.06,0H10.92A3.65,3.65,0,0,0,7.28,3.64V17.48H8.74Z"
        />
        <rect class="s2" x="10.92" y="2.91" width="1.46" height="1.46" />
        <rect class="s2" x="13.84" y="2.91" width="1.46" height="1.46" />
        <rect class="s2" x="16.75" y="2.91" width="1.46" height="1.46" />
        <rect class="s2" x="10.92" y="5.83" width="29.13" height="1.46" />
        <path
          class="s2"
          d="M20.39,21.12a5.1,5.1,0,1,1,5.1,5.1,5.11,5.11,0,0,1-5.1-5.1m11.66,0a6.56,6.56,0,1,0-6.56,6.56,6.56,6.56,0,0,0,6.56-6.56"
        />
        <path
          class="s2"
          d="M23.31,32.78h4.37a.73.73,0,0,0,.72-.73V30.12a10,10,0,0,0,1.4-.57l1.36,1.36a.74.74,0,0,0,1,0l3.09-3.09a.74.74,0,0,0,0-1l-1.36-1.36A10.41,10.41,0,0,0,34.49,24h1.93a.73.73,0,0,0,.72-.73V18.94a.72.72,0,0,0-.72-.73H34.49a9.89,9.89,0,0,0-.57-1.39l1.36-1.37a.74.74,0,0,0,0-1l-3.09-3.08a.72.72,0,0,0-1,0L29.8,12.7a10.07,10.07,0,0,0-1.4-.58V10.2a.72.72,0,0,0-.72-.73H23.31a.72.72,0,0,0-.73.73v1.92a9.47,9.47,0,0,0-1.39.58l-1.37-1.37a.74.74,0,0,0-1,0L15.7,14.42a.74.74,0,0,0,0,1l1.37,1.37a9.47,9.47,0,0,0-.58,1.39H16v1.46h1a.73.73,0,0,0,.7-.55,8.32,8.32,0,0,1,.86-2.07.73.73,0,0,0-.12-.88l-1.23-1.23,2.06-2.06,1.23,1.23a.73.73,0,0,0,.88.12,8.32,8.32,0,0,1,2.07-.86.72.72,0,0,0,.54-.7V10.93H27v1.74a.72.72,0,0,0,.54.7,8.16,8.16,0,0,1,2.07.86.73.73,0,0,0,.88-.12l1.24-1.23,2,2.06L32.5,16.17a.73.73,0,0,0-.11.88,8.68,8.68,0,0,1,.85,2.07.75.75,0,0,0,.71.55h1.74v2.91H34a.74.74,0,0,0-.71.54,8.51,8.51,0,0,1-.85,2.07.72.72,0,0,0,.11.88l1.23,1.24-2,2.05-1.24-1.23a.72.72,0,0,0-.88-.11,8.51,8.51,0,0,1-2.07.85.74.74,0,0,0-.54.71v1.74H24V29.58a.74.74,0,0,0-.54-.71,8.86,8.86,0,0,1-1.34-.47l-.6,1.32a9.15,9.15,0,0,0,1,.4v1.93a.73.73,0,0,0,.73.73"
        />
        <path
          class="s2"
          d="M24,21.12a1.46,1.46,0,1,1,1.45,1.46A1.45,1.45,0,0,1,24,21.12m4.37,0A2.92,2.92,0,1,0,25.49,24a2.91,2.91,0,0,0,2.92-2.92"
        />
      </svg>
    </div>
  );
}
