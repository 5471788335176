import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Countries from "./Countries";

import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { size } from "./common";
import S11 from "./svgs/s11";
import S7 from "./svgs/s7";

function Speaker({ speakers }) {
  const content = [
    {
      title: "Gathering of Visionaries",
      description:
        "Join a global assembly of visionaries, including BFSI leaders, entrepreneurs, investors, and government officials, to explore the vast potential and profound impact of fintech innovation on West Africa's financial ecosystem. Connect with the leaders and changemakers shaping the future of finance in the region.",
      image: <S7 />,
    },
    {
      title: "Innovation Showcase",
      description:
        "Experience cutting-edge innovations from pioneering startups and industry leaders driving West Africa's fintech revolution. Discover the transformative impact of digital banking, regtech, insurtech, and more, as innovators present solutions tailored to the region's unique needs.",
      image: <S8 />,
    },
    {
      title: "Inspirational Keynotes and Enlightening Panels",
      description:
        "Engage with keynote presentations and panel discussions led by experts at the forefront of fintech innovation. Gain insights into emerging trends, disruptive technologies, and the power of strategic partnerships. ",
      image: <S11 />,
    },
    {
      title: "Personalised 1:1 Meetings",
      description:
        "Enhance your summit experience with personalised 1:1 meetings. Our team will identify pre-qualified attendees based on your preferences, reach out on your behalf, and handle scheduling, ensuring seamless connections with key decision-makers.",
      image: <S1 />,
    },
  ];
  const content1 = [
    {
      title: "Networking",
      description:
        "Connect with influential thought leaders, industry veterans, and innovators to discuss and refine ideas.",
    },
    {
      title: "Exclusive Roundtable Break-out Sessions",
      description:
        "Participate in small group discussions led by expert facilitators, fostering dynamic idea exchange.",
    },
  ];

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",

          overflow: "hidden",
        }}
        className="section py-3"
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",

            backgroundSize: "80%",
          }}
        >
          <Container className="py-2">
            <Row>
              <Col lg={12}>
                <h1
                  className=" text-700 pb-0 text-white mt-0 text-uppercase"
                  style={{ fontSize: size ? "2rem" : "1.5rem" }}
                >
                  <b>
                    Driving Financial Inclusion and Economic Empowerment through Fintech Innovation
                  </b>
                </h1>
              </Col>
              <Col lg={6} md={6} sm={12} className="">
                <h5
                  className="text-400 text-white text-justify"
                  style={{
                    lineHeight: "30px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  <br />
                  West Africa is undergoing a transformative shift in its financial sector, fueled
                  by the rapid growth and adoption of fintech innovations. The synergy between
                  traditional banking institutions and fintech companies is emerging as a pivotal
                  force in driving economic growth, particularly in Nigeria, Africa's most populous
                  nation. Amidst economic challenges and evolving regulatory frameworks, fintech is
                  breaking down barriers, enhancing financial inclusion, and empowering underserved
                  communities by providing them with vital financial services.
                  <br />
                  <br />
                  At the epicentre of this fintech revolution, Nigeria is experiencing a significant
                  transformation in its financial landscape. Mobile banking, digital payments, and
                  other fintech innovations are redefining how financial services are delivered,
                  addressing the needs of unbanked and underbanked populations, and contributing to
                  the region's overall economic development. Strategic collaborations between
                  fintech companies and traditional banks are crucial, blending conventional banking
                  products with innovative technological solutions to create a more inclusive
                  financial ecosystem.
                  <br />
                  <br />
                  The Central Bank of Nigeria (CBN) has been instrumental in this transformation,
                  with initiatives such as the eNaira serving as a catalyst for innovation and
                  growth. The evolving regulatory landscape is also opening up opportunities for
                  fintech companies to thrive, attracting significant investments that further fuel
                  innovation and expansion in the sector.
                  <br />
                  <br />
                  <b style={{ fontSize: 25, color: "#009845" }}>
                    28th Edition Finnovex West Africa 2024: The Premier Platform for Fintech
                    Collaboration
                  </b>
                  <br />
                  <br />
                  Finnovex West Africa 2024 will bring together over 200 key stakeholders from
                  across the financial services ecosystem, offering unparalleled networking
                  opportunities. This summit is designed to connect industry leaders, regulators,
                  investors, and innovators, enabling participants to gain insights into the latest
                  trends shaping the fintech landscape and explore new opportunities for growth and
                  innovation. Join us in Lagos for a groundbreaking event that delves into
                  transformative technologies such as AI, blockchain, data analytics, and
                  biometrics, which are revolutionizing the banking industry.
                  <br />
                  <br />
                </h5>
              </Col>
              <Col lg={6} className="mt-5 pr-0">
                {content.map((s, index) => (
                  <ScrollAnimation
                    animateIn={"fadeInUp"}
                    animateOnce={true}
                    duration={0.5 + index / 8}
                  >
                    <div className="py-4 px-2 stat-div mb-2" style={{ minHeight: 200 }}>
                      <div className="container-fluid row pr-0">
                        <Col className="align-self-center" xs={3}>
                          {s.image}
                        </Col>
                        <Col>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "18px" : "14px",
                            }}
                            className="text-700 mt-0"
                          >
                            {s.title}
                          </h3>
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: size ? "16px" : "12px",
                            }}
                            className="text-400 mt-0"
                          >
                            {s.description}
                          </h3>
                        </Col>
                      </div>
                    </div>
                  </ScrollAnimation>
                ))}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12}>
                <h3
                  style={{
                    color: "#fff",
                    fontSize: size ? "36px" : "20px",
                  }}
                  className="text-400 mt-0"
                >
                  Networking and Insights
                </h3>
              </Col>
            </Row>
            <Slider {...settings}>
              {content1.map((data, i) => (
                <div className="d-flex w-100 my-5">
                  <Col lg={6} className="align-self-start">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: "36px",
                      }}
                      className="text-400 mt-0"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data.title}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                    <h4 className="text-400 mt-5 text-white">{data.description}</h4>
                  </Col>
                  <Col lg={6} className="align-self-center">
                    <img src={require(`assets/quotes/${i + 5}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Container>

          <Countries />
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} className="text-center">
                <Button
                  href="/register"
                  className="btn my-2 text-center px-5"
                  color="primary"
                  size="lg"
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="py-5">
            <Row className="justify-content-center">
              {images.map((id, i) => (
                <Col lg={4} xs={12} key={i}>
                  <img
                    alt="..."
                    className=" img-responsive my-3"
                    width="100%"
                    src={require(`assets/images/photos/${id}`)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "20231026-DSC_4699.jpg",
  "20231026-DSC_4705.jpg",
  "20231026-DSC_4709.jpg",
  "20231026-DSC_4723.jpg",
  "20231026-DSC_4728.jpg",
  "20231026-DSC_4744.jpg",
  "20231026-DSC_4870.jpg",
  "20231026-DSC_4874.jpg",
  "20231026-DSC_4954.jpg",
  "20231026-DSC_5015.jpg",
  "20231026-DSC_5114.jpg",
  "20231026-DSC_5210.jpg",
  "20231026-DSC_7553.jpg",
  "20231026-DSC_7562.jpg",
];
