import React, { useEffect } from "react";
import anime from "animejs";

export default function S9({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s9",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={
        {
          // width: 100,
        }
      }
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 52 52"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#009845",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s9"
          d="M15.1,34.76h-3a.29.29,0,0,0,0,.11c0,3,0,6,0,9.07a1.75,1.75,0,0,0,.39.92,1.39,1.39,0,0,0,1.62.43,1.54,1.54,0,0,0,1-1.51v-9m17.4-9a3.78,3.78,0,1,0,3.76,3.81,3.79,3.79,0,0,0-3.76-3.81M35.82,11.2l.27.29c.78.8.78.8.31,1.84,0,0,0,0,0,0s-.25.23-.39.24c-.4,0-.8,0-1.2,0v1.51c.43,0,.82,0,1.21,0a.5.5,0,0,1,.34.16c.58.92.55,1.16-.22,1.94l-.31.32,1.06,1.05.3-.28c.77-.77.77-.77,1.8-.33a.45.45,0,0,1,.33.49c0,.38,0,.77,0,1.14H40.8v-.37c0-1.16,0-1.15,1.09-1.57a.39.39,0,0,1,.52.12c.26.29.53.57.77.81l1.12-1-.28-.3c-.81-.82-.82-.82-.31-1.87a.55.55,0,0,1,.41-.26,11.86,11.86,0,0,1,1.19,0V13.62h-.39c-1.16,0-1.17,0-1.55-1.1a.54.54,0,0,1,.1-.47c.26-.3.57-.56.93-.9a4.37,4.37,0,0,1-.61-.53,5.6,5.6,0,0,1-.42-.62,2.93,2.93,0,0,0-.43.41c-.78.78-.78.77-1.8.35a.45.45,0,0,1-.35-.51c0-.38,0-.75,0-1.13H39.29v.4c0,1.13,0,1.12-1.06,1.54a.42.42,0,0,1-.57-.13c-.23-.31-.48-.6-.69-.86L35.82,11.2m-29.77.58A12.07,12.07,0,0,0,16.63,32.9a11.73,11.73,0,0,0,5.58-3.21c1.54-1.56,3.13-4.53,3-5.65a12.46,12.46,0,0,1-1.23.58,1.16,1.16,0,0,0-.71.74A10.58,10.58,0,1,1,5.77,14.06.88.88,0,0,0,6,13.6c0-.58,0-1.16,0-1.82m.3,4a8.92,8.92,0,0,0-1,9.19A9,9,0,0,0,14,30.24a8.78,8.78,0,0,0,7.51-4.76A13,13,0,0,1,6.35,15.79m12.5,8.42A11.35,11.35,0,1,0,7.54,12.85,11.37,11.37,0,0,0,18.85,24.21m8.68-2L29.75,25a5.32,5.32,0,0,1,5.51,0l3.13-3.76-.61-.08V19.83l-.77,1-3.29-3.32.82-.69a.83.83,0,0,0-.08-.12H33.28V12.12h1.17a.54.54,0,0,0,0-.11l-.84-.7L36.9,8.05l.86.81V7.6H42.3V8.84l.79-.91,3.23,3.34-.79.66.09.16h1.2v4.54H45.64l0,.1.88.72-3.34,3.28-.69-.83-.11.07v1.21c-.56,0-1.09,0-1.62,0a.78.78,0,0,0-.45.17c-1.28,1.52-2.54,3-3.84,4.6a5.26,5.26,0,0,1,1.23,4.66,5.14,5.14,0,0,1-2,3.14,5.3,5.3,0,0,1-7-7.84l-1.64-2A13.57,13.57,0,0,1,16.61,34.45V35c0,3,0,5.92,0,8.88a2.87,2.87,0,0,1-1.81,2.76,2.87,2.87,0,0,1-3.21-.51,2.94,2.94,0,0,1-1-2.35V34.45a13.15,13.15,0,0,1-4.41-1.9A13.16,13.16,0,0,1,0,20.51,13.29,13.29,0,0,1,6.08,9.82a1.37,1.37,0,0,0,.5-.68,12.84,12.84,0,0,1,25,1.65A12.55,12.55,0,0,1,27.87,22a2,2,0,0,1-.34.24"
        />
        <rect class="s9" x="33.27" y="3.06" width="1.46" height="1.46" />
        <rect class="s9" x="36.3" y="3.07" width="1.45" height="1.46" />
        <rect class="s9" x="39.31" y="3.07" width="1.45" height="1.45" />
        <path
          class="s9"
          d="M12.11,18.12h1.45v-6H12.11Zm12.09,0h1.46v-9H24.2Zm-4.58-12H18.15v12h1.47Zm-9,4.52h4.49v7.5H16.6V4.57h4.53V18.13h1.52V7.61h4.52v12H10.6Z"
        />
      </svg>
    </div>
  );
}
