export const sponsors = [
  {
    image: "backbase.png",
    type: "OFFICIAL ENGAGEMENT BANKING SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://www.backbase.com/",
  },
  {
    image: "threatLocker.jpg",
    type: "PLATINUM SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://threatlocker.com/",
  },
  {
    image: "kissflow.jpg",
    type: "GOLD SPONSOR",
    grid: 12,
    smallGrid: 12,
    link: "https://kissflow.com/",
  },

  {
    image: "infosys.jpg",
    type: "STRATEGIC PARTNER ",
    grid: 12,
    smallGrid: 12,
    link: "https://www.edgeverve.com/finacle/",
    a: 6,
  },
  {
    image: "azentio.jpg",
    type: "OFFICIAL TECHNOLOGY PARTNER ",
    grid: 12,
    smallGrid: 12,
    link: "https://www.azentio.com/",
    a: 6,
  },
  {
    image: "comforte_logo.png",
    type: "SILVER SPONSORS",
    grid: 4,
    smallGrid: 4,
    link: "https://www.comforte.com/",
  },
  {
    image: "Concerto-Logo.png",
    type: "SILVER SPONSORS",
    grid: 4,
    smallGrid: 4,
    link: "https://www.concertosoft.com/",
  },
  {
    name: "Neosoft",
    image: "neosoft.png",
    type: "NETWORKING SPONSOR",
    link: "http://www.neosofttech.com/",
    grid: 6,
    text: "NeoSOFT is an SEI-CMMI Level-5 and ISO 9001:2008 certified global IT consulting & software solutions provider with 2000+ software consultants working full time across 7 Delivery Centers. Established in 1996, NeoSOFT is headquartered in Mumbai, India with offices in the USA, UK, Dubai, Italy, Germany, Japan & Australia. Our commitment to quality and 23+ years of experience has made us serve over 1500+ clients across 50+ countries with 85% client retention. They build cohesive technology solutions for the World’s Leading Enterprises, SMEs & Startups and ISV's We cater to more than 22+ Industries like  Banking & Finance - E-Commerce & Retail - Education & E-Learning - Government/ Semi-Government Healthcare - Insurance - IT & Consulting - Manufacturing - Media & Entertainment to name a few.",
  },
  {
    image: "microsoft.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.microsoft.com/",
  },
  {
    image: "oracle.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.oracle.com/in/index.html",
  },
  {
    image: "pwc.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.pwc.in/",
  },
  {
    image: "EY.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.ey.com/en_in",
  },
  {
    image: "tcs.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.tcs.com/",
  },
  {
    image: "expo2020.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.expo2020dubai.com/",
  },
  {
    image: "edb.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.bahrainedb.com/",
  },
  {
    image: "difc.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.difc.ae/",
  },

  {
    image: "oxford.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
  },
  {
    image: "rakbank.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.pwc.in/",
  },

  {
    image: "smartstream.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.smartstream-stp.com/",
  },
  {
    image: "temenos.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.temenos.com/",
  },
  {
    image: "avaya.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.avaya.com/en/",
  },

  {
    image: "bankserv.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.bankservafrica.com/website/",
  },
  {
    image: "beingcrypto.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://beincrypto.com/",
  },
  {
    image: "comviva.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.comviva.com/",
  },
  {
    image: "consdata.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.consdata.com/en/",
  },
  {
    image: "daon.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.daon.com/",
  },
  {
    image: "darktrace.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "darktrace",
  },

  {
    image: "fireeye.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.fireeye.com/",
  },
  {
    image: "freshworks.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.freshworks.com/",
  },
  {
    image: "fss.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.fsstech.com/",
  },
  {
    image: "iib.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://iib.gov.in/",
  },
  {
    image: "infobip.jpeg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.infobip.com/",
  },
  {
    image: "infrasoft.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.infrasofttech.com/",
  },
  {
    image: "ManageEngine.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.manageengine.com/",
  },

  {
    image: "newgen.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://newgensoft.com/home-us/",
  },
  {
    image: "onespan.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.onespan.com/",
  },

  {
    image: "piotech.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.oracle.com/in/index.html",
  },

  {
    image: "recorded.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.recordedfuture.com/",
  },
  {
    image: "scventure.jpg",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://scventures.io/",
  },

  {
    image: "smartmessage.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.smartmessage.com/",
  },

  {
    image: "turbonomic.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://www.turbonomic.com/",
  },
  {
    image: "yethi.png",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "https://yethi.in/",
  },
  {
    name: "network international",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "network.jpg",
  },
  {
    name: "arabnet",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "arabnet.jpeg",
  },
  {
    name: "triwire",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "tripwire.jpeg",
  },
  {
    name: "fintechgalaxy",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "fintechgalaxy.jpeg",
  },
  {
    name: "creatio ",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "creatio.png",
  },
  {
    name: "confluence",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "confluent.jpg",
  },
  {
    name: "abaka",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "abaka.jpg",
  },
  {
    name: "payoneer ",
    type: "Past Sponsors & Partners",
    grid: 3,
    link: "",
    image: "Payoneer.jpeg",
  },
  {
    name: "Unlockbc",
    type: "Media Partner",
    grid: 3,
    link: "http://www.unlock-bc.com/",
    image: "unlock.png",
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const speakers = [
  {
    name: "Adeoluwa Akomolafe",
    title: "Chief Information Officer",
    company: "Wema Bank Plc, Nigeria",
    image: "Adeoluwa Akomolafe.jpeg",
    companyImage: "wema.png",
  },

  {
    name: "Fatai Tella",
    title: "Chief Data Officer",
    company: "Sterling Bank Plc, Nigeria",
    image: "Fatai Tella.jpeg",
    companyImage: "sterling.jpg",
  },

  {
    name: "Chris Esezobor",
    title: "Chief Digital Officer",
    company: "Keystone Bank, Nigeria",
    image: "Chris Esezobor.jpeg",
    companyImage: "keystone.jpg",
  },

  // {
  //   name: "Dr Abdourahmane Faye",
  //   title: "Executive Director - Data Management & Artificial Intelligence",
  //   company: "Vontobel Investment Bank, Zürich",
  //   image: "Abdourahmane.jpg",
  //   companyImage: "vontobel.jpeg",
  // },

  {
    name: "Emmanuel Morka",
    title: "Chief Information Officer",
    company: "Access Bank (Ghana) Plc",
    image: "Emmanuel morka.jpeg",
    companyImage: "Access.png",
  },

  {
    name: "Mounir Jazouli",
    title: "Director Communication, Media & Digital",
    company: "Bank Of Africa, Maroc",
    image: "Mounir Jazouli.jpg",
    companyImage: "boa.jpeg",
  },
  {
    name: "Calleb Osei",
    title: "Regional Chief Financial Officer",
    company: "Guaranty Trust Bank, Ghana",
    image: "Calleb Osei.jpg",
    companyImage: "gtb.png",
  },

  // {
  //   name: "Joseph Ojebola",
  //   title: "Group Chief Financial Officer",
  //   company: "FSL Securities Limited, Nigeria",
  //   image: "Joseph Ojebola.jpg",
  //   companyImage: "",
  // },

  // {
  //   name: "Lanre Bamisebi",
  //   title: "Group Director IT & Operations",
  //   company: "Equity Group Holdings, Kenya",
  //   image: "Lanre Bamisebi.jpeg",
  //   companyImage: "equity.png",
  // },

  {
    name: "Harrison Nnaji",
    title: "Chief Information Security Officer",
    company: "FirstBank Nigeria",
    image: "Harrison Nnaji.jpeg",
    companyImage: "firstbank.png",
  },

  // {
  //   name: "Ndungu Thairu",
  //   title: "Deputy Managing Director",
  //   company: "Consolidated Bank Ghana Limited",
  //   image: "Ndungu Thairu.jpeg",
  //   companyImage: "consolidated.jpg",
  // },

  // {
  //   name: "Adaeze Udensi",
  //   title: "Executive Director",
  //   company: "Titan Trust Bank, Nigeria",
  //   image: "Adaeze Udensi.jpeg",
  //   companyImage: "titan.png",
  // },

  {
    name: "Zekarias Mekonnen Yigzaw",
    title: "Managing Director",
    company: "Commercial Bank of Ethiopia",
    image: "Zekarias Mekonnen.jpeg",
    companyImage: "commercial.jpeg",
  },

  {
    name: "Lynet Owuor- Okoth",
    title: "Head of Customer Experience Department",
    company: "National Bank of Kenya",
    image: "Lynet Owuor.jpeg",
    companyImage: "nbk.png",
  },

  {
    name: "Dr. Philip Oti – Mensah",
    title: "Executive Director",
    company: "OmniBSIC Bank, Ghana",
    image: "Dr. Philip Oti - Mensah.jpeg",
    companyImage: "OmniBSICBank.png",
  },
  {
    name: "George Ogbonnaya",
    title: "Vice President & Group Head BusVice President & Group Head Business Banking",
    company: "First City Monument Bank Limited, Nigeria",
    image: "George.jpeg",
    companyImage: "fcmb.png",
  },
  {
    name: "Bipul Deka",
    title: "Chief Digital Officer",
    company: "First City Monument Bank Limited, Nigeria",
    image: "Bipul Deka.jpg",
    companyImage: "",
  },

  {
    name: "Idowu Korede Dada",
    title: "Group Chief Risk & Investment Officer",
    company: "TrustBanc Financial Group, Nigeria",
    image: "Idowu Dada.jpeg",
    companyImage: "trustbanc.jpeg",
  },
  {
    name: "Femi Olofintila",
    title: "Chief Information Security Officer",
    company: "Nova Merchant Bank Ltd, Nigeria",
    image: "Femi Olofintila.jpeg",
    companyImage: "nova.jpeg",
  },
  {
    name: "Nduneche Ezurike",
    title: "Group Head of Brand and Marketing Communications",
    company: "Polaris Bank Ltd, Nigeria",
    image: "NDUNECHE PIX. NEW.jpeg",
    companyImage: "polaris.png",
  },

  {
    name: "Abdul Wasiu Popoola",
    title: "Chief Digital Officer",
    company: "Parallex Bank Ltd, Nigeria",
    image: "AbdulWasiu Popoola.jpeg",
    companyImage: "parallax.png",
  },
  {
    name: "Funmi Akinfenwa",
    title: "Head Of Operations",
    company: "Zenith Bank Plc, Nigeria",
    image: "Funmi Sphri.jpg",
    companyImage: "",
  },

  {
    name: "Adewale I. Salami",
    title: "Chief Technology Officer",
    company: "First Bank of Nigeria Limited, Nigeria",
    image: "Adewale I. Salami.jpg",
    companyImage: "FBNBank.png",
  },

  {
    name: "Adebayo Osagie Olarewaju",
    title: "Head Digital Channels, E-Business & Retail Prodcuts",
    company: "First Bank of Nigeria Limited, Nigeria",
    image: "Adebayo Olarewaju.jpg",
    companyImage: "FBNBank.png",
  },

  {
    name: "Bola Atta",
    title: "Group Director, Corporate Communication",
    company: "UBA Group, Nigeria",
    image: "Bola Atta.jpg",
    companyImage: "uba.png",
  },

  {
    name: "Osahon Akpata",
    title: "Group Head, Consumer Payments",
    company: "Ecobank Transnational Incorporated, Nigeria",
    image: "Osahon Akpata Pic.jpg",
    companyImage: "Ecobank.png",
  },

  {
    name: "Emmanuel Onyeje",
    title: "Regional Manager West Africa and Head of Nigeria",
    company: "Backbase",
    image: "Emmanuel Onyeje - Backbase.jpg",
    companyImage: "backbase.jpeg",
  },

  {
    name: "Grace Anyetei",
    title: "Regional Director of Operations, Africa - Leaf, ",
    company: "IDT Corporation, Kenya",
    image: "Grace Anyetei - Profile pic.jpg",
    companyImage: "",
  },

  {
    name: "Barnabas Okwudibie",
    title: "Chief Operating Officer",
    company: "Links MFB Limited (a subsidiary of DLM Capital Group)",
    image: "Barnabas Okwudibie.jpg",
    companyImage: "",
  },

  {
    name: "Pattison Boleigha",
    title: "Group Chief Conduct and Compliance Officer,",
    company: "Access Bank Plc",
    image: "Pattison Boleigha.jpg",
    companyImage: "Access.png",
  },

  {
    name: "Oluremi Tinuolu-Gabriel",
    title: "Group Head, Digital Channels",
    company: "Access Bank Plc, Nigeria",
    image: "Oluremi Tinuolu-Gabriel.JPG",
    companyImage: "Access.png",
  },

  {
    name: "Jude Monye",
    title: "Executive Director/Executive Compliance Officer",
    company: "Heritage Bank Plc, Nigeria",
    image: "Jude Monye.jpg",
    companyImage: undefined,
  },

  {
    name: "Olumuyiwa Theophilus",
    title: "Group Chief Information Officer",
    company: "Equity Bank Limited",
    image: "Olumuyiwa Theophilus.jpg",
    companyImage: "equity.png",
  },

  {
    name: "Ikemefula N. Nwachukwu",
    title: "Head, Personal Banking Department",
    company: "First Bank of Nigeria Limited",
    image: "Ikemefula N. Nwachukwu.png",
    companyImage: "FBNBank.png",
  },

  {
    name: "Ope Okesola",
    title: "Deputy Chief Information Officer",
    company: "African Alliance Insurance PLC Nigeria",
    image: "Ope Okesola.jpg",
    companyImage: undefined,
  },
];

export const pastspeakers = [];
