import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";

import S7 from "./svgs/s7";
import S8 from "./svgs/s8";
import S11 from "./svgs/s11";
import S1 from "./svgs/s1";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Gathering of Visionaries",
      description:
        "Join a global assembly of visionaries, including BFSI leaders, entrepreneurs, investors, and government officials, to explore the vast potential and profound impact of fintech innovation on West Africa's financial ecosystem. Connect with the leaders and changemakers shaping the future of finance in the region.",
      image: <S7 />,
    },
    {
      title: "Innovation Showcase",
      description:
        "Experience cutting-edge innovations from pioneering startups and industry leaders driving West Africa's fintech revolution. Discover the transformative impact of digital banking, regtech, insurtech, and more, as innovators present solutions tailored to the region's unique needs.",
      image: <S8 />,
    },
    {
      title: "Inspirational Keynotes and Enlightening Panels",
      description:
        "Engage with keynote presentations and panel discussions led by experts at the forefront of fintech innovation. Gain insights into emerging trends, disruptive technologies, and the power of strategic partnerships. ",
      image: <S11 />,
    },
    {
      title: "Personalised 1:1 Meetings",
      description:
        "Enhance your summit experience with personalised 1:1 meetings. Our team will identify pre-qualified attendees based on your preferences, reach out on your behalf, and handle scheduling, ensuring seamless connections with key decision-makers.",
      image: <S1 />,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" text-700 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2rem" : "1.5rem" }}
              >
                <b>
                  Driving Financial Inclusion and Economic Empowerment through Fintech Innovation
                </b>
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                West Africa is undergoing a transformative shift in its financial sector, fueled by
                the rapid growth and adoption of fintech innovations. The synergy between
                traditional banking institutions and fintech companies is emerging as a pivotal
                force in driving economic growth, particularly in Nigeria, Africa's most populous
                nation. Amidst economic challenges and evolving regulatory frameworks, fintech is
                breaking down barriers, enhancing financial inclusion, and empowering underserved
                communities by providing them with vital financial services.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#009845" }}>Theme: Beyond Boundaries</b>
                <br />
                <br />
                At the epicentre of this fintech revolution, Nigeria is experiencing a significant
                transformation in its financial landscape. Mobile banking, digital payments, and
                other fintech innovations are redefining how financial services are delivered,
                addressing the needs of unbanked and underbanked populations, and contributing to
                the region's overall economic development. Strategic collaborations between fintech
                companies and traditional banks are crucial, blending conventional banking products
                with innovative technological solutions to create a more inclusive financial
                ecosystem.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#009845" }}>
                  28th Edition Finnovex West Africa 2024: The Premier Platform for Fintech
                  Collaboration
                </b>
                <br />
                <br />
                Finnovex West Africa 2024 will bring together over 200 key stakeholders from across
                the financial services ecosystem, offering unparalleled networking opportunities.
                This summit is designed to connect industry leaders, regulators, investors, and
                innovators, enabling participants to gain insights into the latest trends shaping
                the fintech landscape and explore new opportunities for growth and innovation. Join
                us in Lagos for a groundbreaking event that delves into transformative technologies
                such as AI, blockchain, data analytics, and biometrics, which are revolutionizing
                the banking industry.
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              {content.map((s, index) => (
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={0.5 + index / 8}
                >
                  <div className="py-4 px-1 stat-div mb-2" style={{ minHeight: 200 }}>
                    <div className="container-fluid row pr-0">
                      <Col className="align-self-center" xs={4}>
                        {s.image}
                      </Col>
                      <Col>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-0"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </Col>
                    </div>
                  </div>
                </ScrollAnimation>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
