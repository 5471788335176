import React from "react";
import { Container, Button, Row, Col, Card } from "reactstrap";
import Slider from "react-slick";
const settings = {
  dots: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 3000,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  className: "p-10",
  customPaging: function (i) {
    return <div className="dot"></div>;
  },
  dotsClass: "slick-dots slick-thumb",
};
function Quotes() {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return (
    <div
      style={{
        background: "transparent",
      }}
      className="section py-5"
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <Slider {...settings}>
              {content.map((data, i) => (
                <div className={size ? "d-flex" : "container row mb-5"}>
                  <Col md={6} xs={12} className="align-self-center">
                    <h3
                      style={{
                        color: "#fff",
                        fontSize: size ? "36px" : "18px",
                      }}
                      className="text-header mt-0 mb-3"
                    >
                      <i class="fa fa-quote-left text-primary" aria-hidden="true"></i>{" "}
                      <span className="pl-4 pr-4">{data}</span>
                      <i class="fa fa-quote-right text-primary" aria-hidden="true"></i>
                    </h3>
                  </Col>
                  <Col lg={6} xs={12}>
                    <img src={require(`assets/quotes/${i + 1}.jpg`)} width="100%" alt="main logo" />
                  </Col>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Quotes;
const content = [
  "Accelerate Your Business: Collaborate with Visionaries to Fuel Unstoppable Growth!",
  "Transform Your Future: Harness Cutting-Edge Solutions for Unrivalled Success.",
  "Elevate Your Influence: Partner with Pioneers to Lead the Industry.",
  "Empower Your Ambitions: Engage with Innovators for Lasting Impact and Success!",
];
