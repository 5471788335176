import React from "react";
import { Row, Container, Col, Button } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      style={{
        backgroundColor: "transparent",
      }}
      className="footer footer-black footer-white text-white"
    >
      <Container fluid>
        <Row>
          <Col lg={4} className="px-5 pt-5 pb-3">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <a href="/">
                    <img
                      src={require("assets/images/logo/wa.png")}
                      alt="vibe"
                      width="100%"
                      style={{ maxWidth: "220px" }}
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            lg={4}
            className="mx-auto px-5 py-3 "
            style={{
              borderLeft: "0.5px solid #dfdfdf",
              borderRight: "0.5px solid #dfdfdf",
            }}
          >
            <a href="/" className="mx-auto">
              <img
                src={require("assets/images/logo/exibex.png")}
                alt="exibex"
                width="50%"
                style={{ maxWidth: "200px" }}
              />
            </a>
            <h5 className="text-left text-700">CONTACT US</h5>

            <p className="text-left p-0 mt-0">
              Exibex FZ LLE
              <br />
              Office 1309,13th Floor, Creative Tower, Fujairah
              <br />
              United Arab Emirates
              <br />
              +44 20 3290 7867 | +91 9980028128
              <br />
              Email: info@exibex.com
            </p>
          </Col>
          <Col lg={4} className="px-5 py-3">
            <h3 className="text-700 pb-4">FOLLOW US</h3>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: "#009845", color: "#fff" }}
              href="https://www.linkedin.com/showcase/finnovex-middle-east/"
            >
              <i className="fa fa-linkedin" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: "#009845", color: "#fff" }}
              href="https://twitter.com/Finnovexlive"
            >
              <i className="fa fa-twitter" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: "#009845", color: "#fff" }}
              href="https://www.instagram.com/finnovex/"
            >
              <i className="fa fa-instagram" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ backgroundColor: "#009845", color: "#fff" }}
              href="https://www.youtube.com/channel/UCfxEoE4GycX1m7YeHdOZQXQ"
            >
              <i className="fa fa-youtube" />
            </Button>
            <Button
              className="btn-neutral btn-just-icon"
              style={{ backgroundColor: "#009845", color: "#fff" }}
              href="https://wa.me/971585518437?text=Hi"
            >
              <i className="fa fa-whatsapp" />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className={"text-center text-white pt-1 m-auto"}>
            <p className="text-400">©2024 EXIBEX FZ LLE. All Right Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
